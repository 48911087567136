import "init";

import "bootstrap";

import "components/application/navbar/navbar";

import "components/application/mood_image/mood_image";

import "components/application/main_content/main_content";

import "components/shared/footer/footer_block";

import "stylesheets/application.scss";

import "stylesheets/buttons.pcss";

import "stylesheets/privacy.pcss";

import "scripts/partner/whatsapp.js";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");